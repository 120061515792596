<template>
  <div>
    <form @submit.prevent="save" ref="form">
      <BaseModal v-model="showModal" @close-modal="closeModal">
        <template v-slot:header> {{ $t('conflicts.header') }} </template>
        <div class="px-4 py-6 sm:p-8 flex flex-col gap-4" v-if="conflictCount && a">
          <div class="w-full grid grid-cols-4 grid-rows-2 relative">
            <!-- Box 1 -->
            <div
              class="px-4 border rounded-xl bg-blue-100/50 border-blue-500 -mb-6 grid content-between justify-items-center ml-0 mr-2 justify-self-stretch justify-center"
              :class="{
                'bg-blue-100/50 z-0': visibleResolve != 'a',
                'bg-blue-200 z-10': visibleResolve == 'a',
                '-mb-6': visibleResolve != 'b' && handleable,
                'mb-6': visibleResolve == 'b',
                'row-span-2 place-self-center self-stretch': !handleable,
                'content-start': visibleResolve == null,
                'content-between': visibleResolve != null
              }"
            >
              <div class="justify-self-center flex flex-col justify-center">
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(a.started_at)).toLocaleTimeString() }}
                </div>
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(a.started_at)).toLocaleDateString() }}
                </div>
              </div>
              <div
                class="border rounded-xl bg-red-100/50 border-red-500 grid justify-center justify-items-center ml-2 justify-self-stretch min-h-48 w-full px-8 py-0 mr-8"
                v-show="!handleable"
              >
                <div class="flex flex-col justify-center self-start">
                  <div class="font-semibold items-cente">
                    {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                  </div>
                  <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                    {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                  </div>
                </div>
                <div class="justify-self-center flex flex-col justify-center self-end">
                  <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                    {{ new Date(Date.parse(b.ended_at)).toLocaleDateString() }}
                  </div>
                  <div class="font-semibold items-center">
                    {{ new Date(Date.parse(b.ended_at)).toLocaleTimeString() }}
                  </div>
                </div>
              </div>
              <div
                v-show="visibleResolve == 'a' || !handleable"
                class="flex flex-col justify-center"
              >
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
                </div>
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
                </div>
              </div>
              <div v-show="visibleResolve == 'b'" class="flex flex-col justify-center">
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                </div>
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                </div>
              </div>
            </div>

            <div
              class="col-span-3 grid grid-rows-4 grid-cols-1 items-end pb-2"
              :class="{ ' col-start-2': !handleable }"
            >
              <div class="p-4 grid grid-cols-3 gap-2 row-span-3">
                <div>
                  <div class="text-sm text-gray-400">Kunde</div>
                  <div>{{ clientsStore.getId(a.client_id)?.name }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-400">Projekt</div>
                  <div>{{ projectsStore.getId(a.project_id)?.name }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-400">Dienstleistung</div>
                  <div>{{ servicesStore.getId(a.service_id)?.name }}</div>
                </div>
                <div class="sm:col-span-3">
                  <div class="text-sm text-gray-400">Beschreibung</div>
                  <div>{{ a.description }}</div>
                </div>
              </div>

              <div class="flex flex-row justify-start items-center">
                <div
                  class="flex-grow border-t border-dotted max-w-24"
                  :class="{ 'border-blue-500': !handleable, 'border-red-500': handleable }"
                ></div>
                <div class="flex-shrink mx-4 relative" v-show="handleable">
                  <div class="flex gap-1 items-baseline">
                    <div class="font-semibold">
                      {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                    </div>
                    <div class="text-gray-600 text-sm" v-show="differentDays">
                      {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                    </div>
                  </div>
                  <div class="absolute top-5 -left-12">
                    <button
                      class="text-red-600 hover:text-red-800 px-2"
                      @mouseover="hover_one = true"
                      @mouseleave="hover_one = false"
                      @click="resolve(true)"
                    >
                      <font-awesome-icon :icon="['fa-kit', 'tl-arrow-turn-down']" size="xl" />
                    </button>
                  </div>
                  <div class="absolute top-5 -right-12">
                    <button
                      class="text-blue-600 hover:text-blue-800 px-2"
                      @mouseover="hover_two = true"
                      @mouseleave="hover_two = false"
                      @click="resolve(false)"
                    >
                      <font-awesome-icon :icon="['fa-kit', 'tl-arrow-turn-up']" size="xl" />
                    </button>
                  </div>
                </div>
                <div
                  class="flex-grow border-t border-dotted"
                  :class="{ 'border-blue-500': !handleable, 'border-red-500': handleable }"
                ></div>
              </div>
            </div>

            <div
              class="border rounded-xl bg-red-100/50 border-red-500 grid justify-center justify-items-center ml-2 mr-0 justify-self-stretch"
              v-show="handleable"
              :class="{
                'bg-red-100/50 z-0': visibleResolve != 'b',
                'bg-red-200 z-10 content-between': visibleResolve == 'b',
                '-mt-6': visibleResolve != 'a',
                'mt-6': visibleResolve == 'a',
                'content-end': visibleResolve == null,
                'content-between': visibleResolve != null
              }"
            >
              <div v-show="visibleResolve == 'a'" class="flex flex-col justify-center">
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
                </div>
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
                </div>
              </div>
              <div v-show="visibleResolve == 'b'" class="flex flex-col justify-center">
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(b.started_at)).toLocaleTimeString() }}
                </div>
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(b.started_at)).toLocaleDateString() }}
                </div>
              </div>
              <div class="justify-self-center flex flex-col justify-center">
                <div v-show="differentDays" class="text-gray-600 text-sm items-center">
                  {{ new Date(Date.parse(b.ended_at)).toLocaleDateString() }}
                </div>
                <div class="font-semibold items-center">
                  {{ new Date(Date.parse(b.ended_at)).toLocaleTimeString() }}
                </div>
              </div>
            </div>

            <div class="col-span-3 grid grid-cols-1 grid-rows-4 items-start pt-2">
              <div class="w-full flex flex-row justify-start items-center">
                <div
                  class="flex-grow border-t border-dotted max-w-24"
                  :class="{ 'border-red-500': !handleable, 'border-blue-500': handleable }"
                ></div>

                <div class="flex-shrink mx-4" v-show="handleable">
                  <div class="flex gap-1 items-baseline">
                    <div class="font-semibold">
                      {{ new Date(Date.parse(a.ended_at)).toLocaleTimeString() }}
                    </div>
                    <div class="text-gray-600 text-sm" v-show="differentDays">
                      {{ new Date(Date.parse(a.ended_at)).toLocaleDateString() }}
                    </div>
                  </div>
                </div>
                <div
                  class="flex-grow border-t border-dotted"
                  :class="{ 'border-red-500': !handleable, 'border-blue-500': handleable }"
                ></div>
              </div>

              <div class="p-4 grid grid-cols-3 gap-2 row-span-3">
                <div>
                  <div class="text-sm text-gray-400">Kunde</div>
                  <div>{{ clientsStore.getId(b.client_id)?.name }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-400">Projekt</div>
                  <div>{{ projectsStore.getId(b.project_id)?.name }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-400">Dienstleistung</div>
                  <div>{{ servicesStore.getId(b.service_id)?.name }}</div>
                </div>
                <div class="sm:col-span-3">
                  <div class="text-sm text-gray-400">Beschreibung</div>
                  <div>{{ b.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!handleable" class="alert-danger">
          Bitte löse diesen Konflikt über den Kalender auf.
        </div>
        <template v-slot:footer>
          <div class="w-full flex justify-between gap-4 items-center">
            <div>
              <BaseButton
                class="button-mossgray"
                @click="gotoConflict"
                type="button"
                ref="goto_button"
                >{{ $t('conflicts.goto') }}</BaseButton
              >
            </div>
            <div>
              <!-- TODO: Add left and right arrow button to move between conflicts -->
              <button
                type="button"
                @click="previous"
                :disabled="
                  actualConflictIndex === null ||
                  actualConflictIndex == 0 ||
                  actualConflictIndex == firstIndex
                "
                class="relative inline-flex items-center rounded-l-full px-2 pl-3 py-2 text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0"
              >
                <span class="sr-only">{{ $t('previous') }}</span>
                <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
              </button>
              <button
                type="button"
                @click="next"
                :disabled="
                  actualConflictIndex === null ||
                  actualConflictIndex == conflictCount - 1 ||
                  actualConflictIndex == lastIndex
                "
                class="relative inline-flex items-center rounded-r-full px-2 pr-3 py-2 text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0"
              >
                <span class="sr-only">{{ $t('next') }}</span>
                <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
              </button>
            </div>

            <div class="flex justify-end gap-4 items-center">
              <BaseButton class="button-gray" @click="closeModal" type="button">{{
                $t('cancel')
              }}</BaseButton>

              <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="!saveable">
                {{ $t('save') }}
              </BaseButton>
            </div>
          </div>
        </template>
        <LoadingSpinner v-model="isLoading" :overAll="true"></LoadingSpinner>
      </BaseModal>
    </form>
  </div>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import timelinkStoresService from '@/services/timelink-stores.service'
import { captureException, lazyLoadIntegration } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { $t } from '@/config/i18n'

const authUserStore = useAuthUserStore()
const timeEntryStore = useTimeEntryStore()
const clientsStore = useClientsStore()
const projectsStore = useProjectsStore()
const servicesStore = useServicesStore()

const isLoading = ref(false)

const showModal = defineModel({ default: false })
const emit = defineEmits(['switchCalendar'])

const actualConflictIndex = defineModel('index', { default: null, type: Number })
const firstIndex = computed(() => {
  return timeEntryStore.conflicts.findIndex((item) => item.type != 9)
})

const lastIndex = computed(() => {
  return timeEntryStore.conflicts.findLastIndex((item) => item.type != 9)
})

const previousIndex = computed(() => {
  return timeEntryStore.conflicts.findLastIndex(
    /**
     *
     * @param {{a: string, b: ?string, type: number}} item
     * @param index
     */
    (item, index) => index < actualConflictIndex.value && item.type != 9
  )
})

const nextIndex = computed(() => {
  return timeEntryStore.conflicts.findIndex(
    /**
     *
     * @param {{a: string, b: ?string, type: number}} item
     * @param index
     */
    (item, index) => index > actualConflictIndex.value && item.type != 9
  )
})

const actual = ref(null)
const a = ref(null)
const b = ref(null)
const hover_one = ref(false)
const hover_two = ref(false)
const win = ref(null)
const type = ref(null)

const goto_button = ref(null)

watch(
  () => showModal.value,
  async (newVal, oldVal) => {
    if (!newVal) {
      resetModal()
    } else if (newVal && !oldVal) {
      await nextTick()
      console.log('Goto button!')
      goto_button.value.focus()
      if (actualConflictIndex.value !== null) {
        loadConflict()
      } else {
        loadNextConflict()
      }
    }
  }
)

watch(
  () => actualConflictIndex.value,
  () => {
    console.log(actualConflictIndex.value)
  }
)

const conflictCount = computed(() => {
  return (timeEntryStore.conflicts ?? []).length
})
const handleableConflictCount = computed(() => {
  return (timeEntryStore.conflicts ?? []).filter((item) => item.type != 9).length
})

const differentDays = ref(true)

const saveable = computed(() => {
  return (
    win.value != undefined &&
    win.value != null &&
    (win.value.id == a.value.id || win.value.id == b.value.id)
  )
})
const visibleResolve = computed(() => {
  if (hover_one.value) {
    return 'a'
  }
  if (hover_two.value) {
    return 'b'
  }

  if (saveable.value) {
    if (win.value.id == a.value.id) {
      return 'a'
    } else {
      return 'b'
    }
  }

  return null
})
const handleable = computed(() => {
  return type.value == null || type.value == 1
})

async function save() {
  isLoading.value = true
  if (!saveable.value) {
    // TODO: Add error message
    return
  }
  if (win.value.id == a.value.id) {
    b.value.started_at = new Date(Date.parse(a.value.ended_at)).toISOString()
    timelinkStoresService.updateTl(b.value)
    await timeEntryStore.updateAsync(b.value.id)
  } else {
    a.value.ended_at = new Date(Date.parse(b.value.started_at)).toISOString()
    timelinkStoresService.updateTl(a.value)
    await timeEntryStore.updateAsync(a.value.id)
  }
  timeEntryStore.resolvedConflict(a.value.id, b.value.id)
  useAlertsStore().success($t('conflicts.success'))
  isLoading.value = false
  loadNextConflict()
}

function loadConflict() {
  console.log('loadconflict', actualConflictIndex.value)
  let conflict = timeEntryStore.conflicts[actualConflictIndex.value] ?? null
  console.log('conflict', conflict)
  if (!conflict && actualConflictIndex.value == 0 && conflictCount.value == 0) {
    closeModal()
    return
  }
  if (!conflict) {
    closeModal()
    return
  }
  actual.value = conflict

  let first = timeEntryStore.getId(conflict.a)
  let second = timeEntryStore.getId(conflict.b)
  if (!first || !second) {
    closeModal()
    return
  }
  try {
    a.value = Date.parse(first.started_at) < Date.parse(second.started_at) ? first : second
    b.value = a.value.id == first.id ? second : first
    type.value = conflict.type
    win.value = null
  } catch (error) {
    captureException(error)
    closeModal()
    return
  }
}

function loadNextConflict() {
  if (handleableConflictCount.value == 0) {
    closeModal()
    return
  }
  if (actualConflictIndex.value >= conflictCount.value) {
    previous()
    return
  }

  let next = timeEntryStore.conflicts[actualConflictIndex.value] ?? null

  if (next && next.type != 9) {
    loadConflict()
    return
  }

  actualConflictIndex.value = actualConflictIndex.value - 1
  next()
}

function closeModal() {
  showModal.value = false
  actualConflictIndex.value = null
  isLoading.value = false
}

function resetModal() {
  a.value = null
  b.value = null
  win.value = null
  type.value = null
}

function gotoConflict() {
  emit('switchCalendar', new Date(Date.parse(b.value.started_at)))
  setTimeout(() => {
    closeModal()
  }, 100)
}

function resolve(aWon) {
  if (aWon) {
    win.value = a.value
  } else {
    win.value = b.value
  }
}

async function previous() {
  if (previousIndex.value == -1) {
    console.log('no previous', actualConflictIndex.value, previousIndex.value)
    resetModal()
    closeModal()
    return
  }
  actualConflictIndex.value = previousIndex.value
  await nextTick()
  console.log(actualConflictIndex.value)
  resetModal()
  loadConflict()
}

async function next() {
  if (nextIndex.value == -1) {
    await previous()
    return
  }
  actualConflictIndex.value = nextIndex.value
  await nextTick()
  console.log(actualConflictIndex.value)
  resetModal()
  loadConflict()
}
</script>
