<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <div class="py-3 mb-3 flex w-full justify-between items-center">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_project') }}
      </h1>
      <span
        v-if="saveComplete"
        class="text-green-500 pb-1 border-b-4 border-green-500 text-base font-bold inline-block md:ml-4"
        >{{ $t('success_message') }}</span
      >

      <div class="flex flex-row space-x-4 py-3 pl-3">
        <BaseButton class="button-mossgray" @click="showExportModal = true">
          <font-awesome-icon :icon="['fa-kit', 'tl-file-export']" class="mr-2 text-lg" />
          {{ $t('settings.projects.export') }}
        </BaseButton>
      </div>
    </div>
    <!-- TODO: Rework -->
    <div class="space-y-8 divide-y divide-mossgray-200 pb-16">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7">
            {{ $t('project_edit_header') }}
          </h2>

          <div
            class="w-full h-full col-span-1"
          >
            <div class="w-full h-full flex justify-center items-center flex-col gap-2">
              <div
                class="flex justify-center items-center text-center text-sm leading-6 text-gray-600 w-32 h-32 aspect-square squircle-clip"
                :style="{
                  color: !project.image_id
                    ? timelinkService.generateForegroundColor(
                        project.color ? project.color : timelinkService.generateBackgroundColor(project)
                      )
                    : null,
                  backgroundColor: !project.image_id
                    ? project.color
                      ? project.color
                      : timelinkService.generateBackgroundColor(project)
                    : null
                }"
              >
                <!-- {{ $t('project_edit_description') }} -->
                <img
                  v-if="project.image_id"
                  :src="timelinkService.getImageFor(project)"
                  class="object-contain"
                />
                <span
                  class="text-4xl font-bold"
                  v-else
                  v-text="timelinkService.acronymOrShortName(project)"
                ></span>
              </div>
              <div class="flex flex-col space-y-2 mt-3">
                <!-- TODO: Translation -->
                <BaseButton class="button button-lime justify-center" @click="showImageUpload = true"
                  >Bild hochladen</BaseButton
                >
                <BaseButton
                  class="button button-raspberry justify-center"
                  v-if="project.image_id"
                  @click="showImageDeleteModal = true"
                  >Löschen</BaseButton
                >
              </div>
            </div>
          </div>
        </div>

        <form
          class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          @submit.prevent="save"
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('name')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="text"
                    name="name"
                    id="name"
                    autocomplete="name"
                    v-model="project.name"
                    class="input w-full"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="acronym" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('acronym')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="text"
                    name="acronym"
                    id="acronym"
                    autocomplete="acronym"
                    v-model="project.acronym"
                    class="input w-full"
                  />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="info" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('info')
                }}</label>
                <div class="mt-2">
                  <textarea
                    :disabled="!canEdit"
                    type="text"
                    name="info"
                    id="info"
                    autocomplete="Off"
                    v-model="project.info"
                    class="textarea w-full"
                  ></textarea>
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('active')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="checkbox"
                    name="active"
                    id="active"
                    autocomplete="Off"
                    v-model="project.active"
                    class="checkbox"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="billable" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('billable')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="checkbox"
                    name="billable"
                    id="billable"
                    autocomplete="Off"
                    v-model="project.billable"
                    class="checkbox"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="color" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('color')
                }}</label>
                <div class="mt-2">
                  <input
                    :disabled="!canEdit"
                    type="color"
                    name="color"
                    id="color"
                    autocomplete="Off"
                    v-model="project.color"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-6"
            :class="{
              'justify-end': companyStore.company.pull_provider,
              'justify-between': !companyStore.company.pull_provider
            }"
          >
            <BaseButton
              class="button-red"
              type="button"
              @click="showDeleteModal = true"
              v-if="!companyStore.company.pull_provider"
              :disabled="!canEdit"
            >
              {{ $t('delete') }}
            </BaseButton>

            <button
              v-if="!companyStore.company.pull_provider"
              type="submit"
              class="button-mossgray"
              :disabled="!canEdit"
            >
              {{ $t('save') }}
            </button>
            <div v-else>
              {{ $t('managed_through') }}

              <span class="font-medium">{{ companyStore.company.pull_provider }}</span
              >.
            </div>
          </div>
        </form>
      </div>
    </div>
    <div>
      <div class="py-2 flex flex-col space-y-2">
        <div class="text-lg">
          {{ $t('page.projects.edit.total_time') }}:
          <span class="ml-2 font-mono">{{ datetime.calcAll(timeEntries) }}</span>
        </div>

        <TimeEntryList 
          :timeEntries="timeEntries" 
          :showBreaks="false" 
          :showActionButtons="false">
        </TimeEntryList>
      </div>
    </div>

    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.project') }}
        </span>
      </div>

      <template #footer>
        <div class="w-full flex justify-end gap-4">
          <BaseButton
            class="button-gray"
            type="button"
            @click="showDeleteModal = false"
            ref="cancelDeleteModalButton"
          >
            {{ $t('cancel') }}</BaseButton
          >
          <BaseButton
            class="button-red"
            :disabled="!timer || timer.seconds > 0"
            @click="this.delete"
            ><span class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span
            >{{ $t('delete') }}</BaseButton
          >
        </div>
      </template>
    </BaseModal>

    <ExportModal
      v-model="showExportModal"
      :project_id="this.$route.params.project_id"
    ></ExportModal>
  </TemplateComponent>
  <template>
    <ImageUploadModal
      v-model="showImageUpload"
      :uploadUrl="uploadUrl"
      @upload-successfully="onUploadSuccessfully"
    />
  </template>  
</template>

<script>
import apiService from '@/services/api.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import featureFlagsService from '@/services/feature-flags.service'

import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useProjectsStore } from '@/stores/projects'
import { useClientsStore } from '@/stores/clients'
import { useServicesStore } from '@/stores/services'
import { useAlertsStore } from '@/stores/alerts'
import { useTimeEntryStore } from '@/stores/timeEntry'

import { useTimer } from 'vue-timer-hook'
import { AxiosError } from 'axios'
import datetime from '@/lib/datetime'
import { captureException, captureMessage } from '@sentry/vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import TimeEntryList from '@/components/general/TimeEntryList.vue'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import ExportModal from '@/components/modals/ExportModal.vue'
import ImageUploadModal from '@/components/modals/ImageUploadModal.vue'


export default {
  components: {
    BaseButton,
    BaseModal,
    ExportModal,
    LoadingSpinner,
    TimeEntryList,
    TemplateComponent,
    ImageUploadModal
  },
  setup() {
    const timelinkService = timelinkStoresService
    const authUserStore = useAuthUserStore()
    const companyStore = useCompanyStore()
    const timeEntryStore = useTimeEntryStore()
    const clientsStore = useClientsStore()
    const projectsStore = useProjectsStore()
    const servicesStore = useServicesStore()
    const feature = featureFlagsService
    const dt = datetime
    return {
      timelinkService,
      authUserStore,
      companyStore,
      timeEntryStore,
      clientsStore,
      projectsStore,
      servicesStore,
      datetime: dt,
      feature
    }
  },
  data() {
    return {
      canEdit: false,
      project: {
        name: null,
        info: null
      },
      isLoading: true,
      saveComplete: null,
      showDeleteModal: false,
      timer: null,
      timeEntries: [],
      showExportModal: false,
      showImageUpload: false,
      uploadUrl: import.meta.env.VITE_API_URL + '/api/v1/projects/' + this.$route.params.project_id + '/image'
    }
  },
  mounted() {
    // this.canEdit = useAuthUserStore().user.admin >= 5 && !useCompanyStore().company.pull_provider
    this.canEdit = useAuthUserStore().user.admin >= 9 && !useCompanyStore().company.pull_provider
    this.fetch()
    this.fetchTimeEntries()
  },
  watch: {
    showDeleteModal(newVal) {
      if (newVal) {
        this.timer = this.timer = useTimer(new Date(Date.now() + 2 * 1000), true)
        this.$nextTick(() => {
          this.$refs.cancelDeleteModalButton?.focus()
        })
      }
    }
  },
  computed: {},
  methods: {
    async fetch() {
      this.isLoading = true
      try {
        let data = await apiService.fetchId(
          import.meta.env.VITE_API_URL + '/api/v1/projects',
          this.$route.params.project_id
        )
        this.project = data.data
        this.project.tl = { image: null }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false

        if (error instanceof AxiosError) {
          if (error?.response?.status == 404 || error?.response?.status == 403) {
            this.$router.push({
              name: 'ClientEdit',
              params: {
                client_id: this.$route.params.client_id
              }
            })
            useAlertsStore().error(this.$t('errors.project.not_found'))
          }
        }
      }

      this.isLoading = false
    },
    async save() {
      if (useCompanyStore().company.pull_provider || useAuthUserStore().user.admin < 5) {
        useAlertsStore().error(this.$t('errors.no_permissions'))
        return
      }
      this.isLoading = true

      try {
        let response = await apiService.update(
          import.meta.env.VITE_API_URL + '/api/v1/projects',
          this.$route.params.project_id,
          {
            ...this.project
          }
        )
        if (response.success) {
          useAlertsStore().successfullySaved()
          timelinkStoresService.getImageFor(this.project)
          useProjectsStore().updateIfExists(response.data)
        } else {
          useAlertsStore().error(this.$t('errors.ups_save'))
          captureMessage('Project save got success = false')
        }
      } catch (error) {
        if (
          error?.response?.status == 403 &&
          error.response.data.message == 'You are not subscribed!'
        ) {
          // TODO: Add better error handling!
          useAlertsStore().error(this.$t('errors.no_subscription'))
        } else if (apiService.checkIfServerError(error)) {
          //
        } else {
          useAlertsStore().error(this.$t('errors.ups'))
          captureException(error)
        }
      }
      this.isLoading = false
    },

    delete() {
      this.isLoading = true
      apiService.delete(
        import.meta.env.VITE_API_URL + '/api/v1/projects',
        this.$route.params.project_id,
        {},
        () => {
          this.$router.push({
            name: 'ClientEdit',
            params: { client_id: this.$route.params.client_id }
          })
        },
        (error) => {
          if (
            error?.response?.status == 403 &&
            error.response.data.message == 'You are not subscribed!'
          ) {
            // TODO: Add better error handling!
            useAlertsStore().error(this.$t('errors.no_subscription'))
          } else {
            useAlertsStore().error(this.$t('errors.ups'))
            captureException(error)
          }
          this.isLoading = false
        }
      )
    },

    fetchTimeEntries() {
      this.timeEntries = []
      //TODO: Add all parameter to get all entries if it is an project manager or admin for this project
      apiService.fetchAll(
        import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
        {
          project_id: this.$route.params.project_id,
          withRelations: true,
          limit: 10
        },
        (data) => {
          if (Array.isArray(data.data)) {
            data.data.forEach((item) => {
              if (item.client) {
                useClientsStore().addOrUpdate(item.client)
              }
              if (item.service) {
                useServicesStore().addOrUpdate(item.service)
              }
              this.timeEntries.push(item)
            })
          }
        },
        (error) => {
          captureException(error)
        }
      )
    },

    onUploadSuccessfully(data) {
      this.project = data
      this.project.tl = {
        image: null
      }
    },

    calcDay(day) {
      return datetime.calcDay(day, this.timeEntries)
    },

    calcWeek(day) {
      return datetime.calcWeek(day, this.timeEntries)
    }
  }
}
</script>
