import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import deckService from '@/services/deck.service'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { usePanelStore } from '@/stores/panel'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { setClient, setSelectableClient } from '../helper/clients'
import { useAuthUserStore } from '@/stores/auth-user'
import { setCPS } from '../helper/cups'
import timelinkStoresService from '@/services/timelink-stores.service'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
async function viewStart(panel, arr) {
  // useClientsStore().fetchActiveCount()
  const dims = deckService.getDimensions()
  useClientsStore().fetch(
    {
      limit: dims.columns * dims.rows * 1,
      page: 1,
      orders: [
        {
          column: 'name',
          direction: 'asc'
        }
      ]
    },
    false,
    true
  )

  panel.setId(arr, 0, {
    type: 'image',
    text: $t('panel.start'),
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-play' })),
    bgColor: '#FA78AB',
    init: (item) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        useTimeEntryStore().$subscribe((mutation, payload) => {
          setStartWithPreviousEntryData(panel, arr)
        })
      )
    },
    callback: (item, panelArr, type) => {
      return async () => {
        if (await !useTimeEntryStore().createNewActiveTimeEntry(null, false, true)) {
          throw new Error('No active time entry was created!')
        }
        usePanelStore().updateWizard(false)
      }
    }
  })

  const handlerName = panel.getHandlerName(arr[0])





  setLatestClients(panel, arr)
  setLatestProjects(panel, arr)
  setStartWithPreviousEntryData(panel, arr)
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 */
function setStartWithPreviousEntryData(panel, arr) {
  const dims = deckService.getDimensions()

  const timeEntries = useTimeEntryStore()
    .getInterval(Date.now() - 2 * 24 * 60 * 60 * 1000, Date.now())
    .sort((a, b) => {
      return Date.parse(b.started_at) - Date.parse(a.started_at)
    })

  const timeEntry = timeEntries[0]

  if (timeEntry) {
    if (usePanelStore().view != 'start') {
      return
    }
    panel.setId(arr, dims.columns - 1, {
      type: 'image',
      text: $t('panel.start'),
      image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
      bgColor: '#FA78AB',
      callback: (item, panelArr, type) => {
        return async () => {
          const timeEntries = useTimeEntryStore()
            .getInterval(Date.now() - 2 * 24 * 60 * 60 * 1000, Date.now())
            .sort((a, b) => {
              return Date.parse(b.started_at) - Date.parse(a.started_at)
            })

          const timeEntry = timeEntries[0]

          usePanelStore().updateWizard(false)

          if (
            await useTimeEntryStore().createNewActiveTimeEntry(
              {
                client_id: timeEntry.client_id ?? null,
                project_id: timeEntry.project_id ?? null,
                service_id: timeEntry.service_id ?? null
              },
              false,
              true
            )
          ) {
            await usePanelStore().updateView('recording')
          } else {
            throw new Error('No active time entry was created!')
          }
        }
      }
    })
  } else {
    if (usePanelStore().view != 'start') {
      return
    }
    panel.resetId(arr, dims.columns - 1)
  }
}

/**
 *
 * @param {import('@/stores/timeEntry').TimeEntry} timeEntry
 */
function test(timeEntry) { }

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 */
function setLatestClients(panel, arr) {
  const dims = deckService.getDimensions()

  // const latest_clients = useAuthUserStore().lastUsed('clients')

  const lc = useClientsStore().clients.slice(0, 4)
  let i = 1
  if (usePanelStore().view != 'start') {
    return
  }

  if (lc.length == 0) {
    panel.setId(arr, dims.columns, {
      text: '',
      type: 'text',
      init: (item) => {
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(item),
          useClientsStore().$subscribe((mutation, payload) => {
            setLatestClients(panel, arr)
          })
        )
      }
    })
    return
  }

  panel.setId(arr, dims.columns, {
    text: $t('client', 2),
    type: 'image',
    color: '#fff',
    bgColor: '#D1D973',
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clients' })),
    init: (item) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        useClientsStore().$subscribe((mutation, payload) => {
          setLatestClients(panel, arr)
        })
      )
    }
  })

  lc.forEach((client) => {
    setCPS(panel, arr, dims.columns + i, client, {
      bgColor: '#D1D973',
      callback: (item, panelArr, type) => {
        return () => {
          usePanelStore().updateWizard(true)
          useTimeEntryStore().createNewActiveTimeEntry({ client_id: client.id }, false, true)
        }
      }
    })
    i++
  })
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 */
function setLatestProjects(panel, arr) {
  const dims = deckService.getDimensions()

  const lc = useProjectsStore().projects.slice(0, 4)
  let i = 1
  // To prevent loading of entries after starting a new tracking
  if (usePanelStore().view != 'start') {
    return
  }

  if (lc.length == 0) {
    panel.setId(arr, dims.columns * 2, {
      text: '',
      type: 'text',
      init: (item) => {
        timelinkStoresService.setOrRenewTimeout('panel', panel.getHandlerName(item), () => {
          useProjectsStore().fetch()
        }, 2000)
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(item),
          useProjectsStore().$subscribe((mutation, payload) => {
            setLatestProjects(panel, arr)
          })
        )
      }
    })
    return
  }

  panel.setId(arr, dims.columns * 2, {
    text: $t('project', 2),
    type: 'image',
    bgColor: '#B0D9EB',
    image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-projects' })),
    init: (item) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(item),
        useProjectsStore().$subscribe((mutation, payload) => {
          setLatestProjects(panel, arr)
        })
      )
    }
  })

  lc.forEach((project) => {
    setCPS(panel, arr, dims.columns * 2 + i, project, {
      bgColor: '#B0D9EB',
      callback: (item, panelArr, type) => {
        return () => {
          usePanelStore().updateWizard(true)
          useTimeEntryStore().createNewActiveTimeEntry(
            { client_id: project.client_id, project_id: project.id },
            false,
            true
          )
        }
      }
    })
    i++
  })
}

export default viewStart
