<template>
  <div class="flex flex-col h-full min-h-screen px-6 py-3 site-background basis-full">
    <LoadingSpinner :full-page="true" v-model="isLoading" :over-all="true" />
    <div class="flex items-center justify-between w-full mb-5">
      <h1 class="text-2xl font-bold">{{ $t('my_account') }}</h1>

      <BaseButton class="flex space-x-4 button-mossgray" @click="logout" type="button">
        <font-awesome-icon :icon="['fa-kit', 'tl-log-out']" />
        <div class="text-center">
          {{ $t('menu.logout') }}
        </div>
      </BaseButton>
    </div>

    <div class="divide-y divide-mossgray-200">
      <!-- START Profile Data -->
      <div class="pb-16 space-y-8 divide-y divide-mossgray-200">
        <div class="grid grid-cols-1 pt-3 pb-6 gap-x-8 gap-y-8 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-lg font-bold leading-7">
              {{ $t('personal_data') }}
            </h2>
            <p class="mt-1 text-sm leading-6">
              {{ $t('personal_description') }}
            </p>
          </div>

          <form class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2" ref="form"
            @submit.prevent="save">
            <div class="px-4 py-6 sm:p-8">
              <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label for="first-name" class="input-label label-required">{{
                    $t('first_name')
                    }}</label>
                  <div class="mt-2">
                    <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                      :disabled="companyStore?.company?.pull_provider" v-model="user.first_name" class="w-full input"
                      required />
                  </div>
                  <div class="text-sm text-red-500" v-if="errors.first_name">
                    <template v-for="message in errors.first_name" :key="message">
                      <div>
                        {{ message }}
                      </div>
                    </template>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="last-name" class="input-label label-required">{{
                    $t('last_name')
                    }}</label>
                  <div class="mt-2">
                    <input type="text" name="last-name" id="last-name" :disabled="companyStore?.company?.pull_provider"
                      autocomplete="family-name" v-model="user.last_name" class="w-full input" required />
                  </div>
                  <div class="text-sm text-red-500" v-if="errors.last_name">
                    <template v-for="message in errors.last_name" :key="message">
                      <div>
                        {{ message }}
                      </div>
                    </template>
                  </div>
                </div>

                <div class="sm:col-span-6">
                  <label for="email" class="input-label label-required">{{ $t('email') }}</label>
                  <div class="mt-2">
                    <input id="email" name="email" type="email" autocomplete="email" disabled v-bind:value="user.email"
                      class="w-full input" required />
                  </div>
                </div>
                <div class="sm:col-span-3" v-if="featureFlags.canUseFeature('language', authUserStore.user)">
                  <label for="language" class="input-label">{{ $t('language') }}</label>
                  <div class="mt-2">
                    <select id="language" name="language" autocomplete="language" v-model="user.language"
                      class="w-full input">
                      <option value="de">{{ $t('languages.de') }}</option>
                      <option value="en">{{ $t('languages.en') }}</option>
                    </select>
                  </div>
                </div>

                <div class="sm:col-span-3" v-if="featureFlags.canUseFeature('language', authUserStore.user)">
                  <label for="timezone" class="input-label">{{ $t('timezone') }}</label>
                  <Combobox as="div" v-model="user.timezone" class="w-full" immediate disabled v-slot="{ open }">
                    <div class="relative mt-2">
                      <ComboboxInput ref="comboTimezone"
                        class="w-full rounded-full border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:ring-mossgray sm:text-sm sm:leading-6 disabled:bg-mossgray-100 disabled:text-mossgray-800"
                        @change="queryTimezone = $event.target.value" :display-value="(timezone) => timezone"
                        autocomplete="off" :placeholder="$t('timezone') + '...'" @blur="resetQueryTimezone(open)" />

                      <ComboboxButton
                        class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none">
                        <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                      </ComboboxButton>

                      <ComboboxOptions
                        class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="item in filteredTimezones" :key="item" :value="item" as="template"
                          v-slot="{ active, selected }">
                          <li :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9 border-b-2 border-gray-200 group',
                            active ? 'bg-mossgray ' : ''
                          ]">
                            <div class="flex flex-col items-start space-y">
                              <span :class="[
                                'ml-3 truncate   text-sm font-semibold',
                                active ? ' text-white' : 'text-black'
                              ]">
                                {{ item }}
                              </span>
                            </div>

                            <span v-if="selected" :class="[
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-mossgray'
                            ]">
                              <font-awesome-icon :icon="['fa-kit', 'tl-check']" />
                            </span>
                          </li>
                        </ComboboxOption>
                        <li class="relative py-2 pl-3 font-semibold text-gray-500 cursor-default select-none pr-9">
                          <div class="flex items-center text-center">
                            <span class="ml-3 truncate"> {{ $t('search_by_typing') }} </span>
                          </div>

                          <!-- TODO: sence of this empty span? can be removed? -->
                          <span class="absolute inset-y-0 right-0 flex items-center pr-4"> </span>
                        </li>
                        <!-- TODO: Placeholder search -->
                      </ComboboxOptions>
                    </div>
                  </Combobox>
                </div>

                <div class="sm:col-span-3 sm:col-start-1" v-if="!companyStore?.company?.force_oauth">
                  <label for="password" class="input-label">{{ $t('password') }}</label>
                  <div class="mt-2">
                    <input type="password" name="password" id="password" ref="password" autocomplete="new-password"
                      v-model="user.password" class="w-full input" :class="{
                        '!ring-red-500':
                          user.password != user.password_confirmation || (errors.password ?? false)
                      }" />
                  </div>
                  <div class="text-sm text-red-500" v-if="errors.password">
                    <template v-for="message in errors.password" :key="message">
                      <div>
                        {{ message }}
                      </div>
                    </template>
                  </div>
                  <div class="text-sm text-red-500" v-if="errors.current_password">
                    <template v-for="message in errors.current_password" :key="message">
                      <div>
                        {{ message }}
                      </div>
                    </template>
                  </div>
                </div>
                <div class="sm:col-span-3" v-if="!companyStore?.company?.force_oauth">
                  <label for="password_confirmation" class="input-label">{{
                    $t('password_confirmation')
                    }}</label>
                  <div class="mt-2">
                    <input type="password" name="password_confirmation" id="password_confirmation"
                      ref="password_confirmation" autocomplete="new-password" v-model="user.password_confirmation"
                      class="w-full input" :class="{
                        '!ring-red-500':
                          user.password != user.password_confirmation || (errors.password ?? false)
                      }" />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
              <BaseButton class="button-mossgray">{{ $t('save') }}</BaseButton>
            </div>
          </form>
        </div>
      </div>
      <!-- END Profile Data -->
      <!-- START api_token -->
      <div class="pb-16 space-y-8 divide-y divide-mossgray-200">
        <div class="grid grid-cols-1 pt-3 pb-6 gap-x-8 gap-y-8 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-lg font-bold leading-7">
              {{ $t('myaccount.api_token.heading') }}
            </h2>
            <p class="mt-1 text-sm leading-6">
              {{ $t('myaccount.api_token.description') }}
            </p>
          </div>

          <div class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2" ref="formApi"
            @submit.prevent="() => { }">
            <div class="px-4 py-6 sm:p-8">
              <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <TransitionGroup appear>
                  <!-- <div class="sm:col-span-6">
                  <span class="block text-sm font-medium leading-6">
                    Token
                  </span>
                  <div class="w-full mt-2">
                    <input class="w-full input" v-if="!apiToken" type="password" placeholder="" value=""
                      autocomplete="off" />

                    <input class="w-full input" v-else :type="plainApiToken ? 'text' : 'password'"
                      :value="plainApiToken ? plainApiToken : '*************'" value="*************">
                    <span class="text-sm text-apricot-700 " v-if="plainApiToken">{{ $t('myaccount.api_token.copy_token')
                      }}</span>
                  </div>
                </div> -->
                  <div class="sm:col-span-6" v-if="apiToken" key="TokenInput">
                    <span class="block text-sm font-medium leading-6">
                      Token
                    </span>
                    <div class="w-full mt-2">
                      <div
                        class="flex w-full divide-x rounded-full ring-1 ring-mossgray-200 divide-mossgray-200 focus-within:ring-2 focus-within:ring-mossgray">
                        <input
                          class="pl-4 text-sm leading-none border-0 rounded-l-full basis-11/12 focus:outline-none focus:ring-0"
                          :type="plainApiToken ? 'text' : 'password'"
                          :value="plainApiToken ? plainApiToken : '*************'" value="*************">
                        <button :disabled="!plainApiToken" @click="copyToClipboard"
                          class="border-0 rounded-r-full cursor-pointer basis-1/12 focus:outline-none focus:ring-0 focus:bg-mossgray focus:text-white disabled:text-mossgray-500 disabled:cursor-auto">
                          <font-awesome-icon :icon="['fak', 'tl-key']" />
                        </button>
                      </div>
                      <span class="text-sm text-apricot-700 " v-if="plainApiToken">{{
                        $t('myaccount.api_token.copy_token')
                      }}</span>
                    </div>
                  </div>
                  <div class="sm:col-span-3" key="TokenCreateButton">
                    <span class="block text-sm font-medium leading-6">
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        <BaseButton class="button-mossgray" @click="showApiTokenModalOrCreate">
                          {{ $t('myaccount.api_token.create_token') }}
                        </BaseButton>
                      </span>
                    </div>
                  </div>
                  <div class="sm:col-span-3" v-if="apiToken" key="TokenDeleteButton">
                    <span class="block text-sm font-medium leading-6">
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        <BaseButton class="button-raspberry" @click="showApiTokenDeleteModal = true">
                          {{ $t('myaccount.api_token.delete_token') }}
                        </BaseButton>
                      </span>
                    </div>
                  </div>
                  <div class="sm:col-start-1 sm:col-span-3" v-if="apiToken" key="TokenLastUsed">
                    <span class="block text-sm font-medium leading-6">
                      {{ $t('myaccount.api_token.last_used') }}
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        {{
                          apiToken.last_used_at
                            ? datetime.date(apiToken.last_used_at).toLocaleString()
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- <div class="sm:col-span-3" v-if="apiToken">
                    <span class="block text-sm font-medium leading-6">
                      {{ $t('myaccount.api_token.expires_at') }}
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        {{
                          apiToken.expires_at
                            ? datetime.date(apiToken.expires_at).toLocaleString()
                            : '-'
                        }}
                      </span>
                    </div>
                  </div> -->
                  <div class="sm:col-span-3" v-if="apiToken" key="TokenCreatedAt">
                    <span class="block text-sm font-medium leading-6">
                      {{ $t('myaccount.api_token.created_at') }}
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        {{
                          apiToken.created_at
                            ? datetime.date(apiToken.created_at).toLocaleString()
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="sm:col-span-3" v-if="apiToken">
                    <span class="block text-sm font-medium leading-6">
                      {{ $t('myaccount.api_token.updated_at') }}
                    </span>
                    <div class="mt-2">
                      <span class="font-bold">
                        {{
                          apiToken.updated_at
                            ? datetime.date(apiToken.updated_at).toLocaleString()
                            : '-'
                        }}
                      </span>
                    </div>
                  </div> -->

                </TransitionGroup>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- END api_token -->
    </div>

  </div>

  <!-- START Get current password -->
  <BaseModal v-model="needCurrentPassword" @close-modal="needCurrentPassword = false">
    <template #header> {{ $t('myaccount.current_password_needed.header') }} </template>

    <div class="flex flex-col gap-4 px-8 py-4">
      <div class="">
        {{ $t('myaccount.current_password_needed.description') }}
      </div>
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="current_password" class="input-label">{{ $t('current_password') }}</label>
          <div class="mt-2">
            <input type="password" name="current_password" id="current_password" autocomplete="current-password"
              ref="current_password" v-model="user.current_password" class="w-full input" :class="{
                '!ring-red-500': errors.current_password ?? false
              }" />
          </div>
          <div class="text-sm text-red-500" v-if="errors.current_password">
            <template v-for="message in errors.current_password" :key="message">
              <div>
                {{ message }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <BaseButton class="button-gray" type="button" @click="needCurrentPassword = false">
          {{ $t('cancel') }}</BaseButton>
        <BaseButton class="button-mossgray" @click="save">{{ $t('confirm') }}</BaseButton>
      </div>
    </template>
  </BaseModal>
  <!-- END Get current password -->

  <!-- START Logout failed modal -->
  <BaseModal v-model="logoutError" @close-modal="logoutError = false">
    <template #header>
      <span class="text-red-600">{{ $t('errors.error_on_logout_header') }} </span>
    </template>
    <div class="p-6">
      <span>
        {{ $t('errors.error_on_logout') }}
      </span>
    </div>
    <template #footer> </template>
  </BaseModal>
  <!-- END Logout failed modal -->

  <BaseModal>
    <template #header>
      {{ $t('myaccount.api_token.create_token_modal.header') }}
    </template>
    <div class="p-6">
      <span></span>
    </div>
  </BaseModal>

  <BaseModal v-model="showCreateTokenModal" @close-modal="showCreateTokenModal = false">
    <template #header> {{ $t('myaccount.api_token.create_token_modal.header') }} </template>

    <div class="p-6">
      <span>
        {{ $t('myaccount.api_token.create_token_modal.description') }}
      </span>
    </div>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <BaseButton class="button-gray" type="button" @click="showCreateTokenModal = false">
          {{ $t('cancel') }}</BaseButton>
        <BaseButton class="button-mossgray" @click="createToken">
          {{ $t('confirm') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>

  <BaseModal v-model="showApiTokenDeleteModal" @close-modal="showApiTokenDeleteModal = false">
    <template #header> {{ $t('myaccount.api_token.delete_token_modal.header') }} </template>

    <div class="p-6">
      <span>
        {{ $t('myaccount.api_token.delete_token_modal.description') }}
      </span>
    </div>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <BaseButton class="button-gray" type="button" @click="showApiTokenDeleteModal = false">
          {{ $t('cancel') }}</BaseButton>
        <BaseButton class="button-mossgray" @click="deleteToken">
          {{ $t('delete') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>

</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useCompanyStore } from '@/stores/company'
import apiService from '@/services/api.service'
import featureFlagsService from '@/services/feature-flags.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import desktopService from '@/services/desktop.service'
import { captureException } from '@sentry/vue'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { useAlertsStore } from '@/stores/alerts'
import LoadingSpinner from '../LoadingSpinner.vue'
import { computed, nextTick, onMounted, ref, TransitionGroup } from 'vue'
import { $t, i18n } from '@/config/i18n'
import datetime from '@/lib/datetime'

// Stores
const authUserStore = useAuthUserStore()
const featureFlags = featureFlagsService
const companyStore = useCompanyStore()
const alertsStore = useAlertsStore()

// Modals
const isLoading = ref(false)
const needCurrentPassword = ref(false)
const logoutError = ref(false)

// Form refs

const form = ref(null)
const comboTimezone = ref(null)
const password = ref(null)
const password_confirmation = ref(null)
const current_password = ref(null)

// Errors
const errors = ref({})

// User
const user = ref({
  first_name: '',
  last_name: '',
  email: '',
  language: null,
  timezone: null
})

// Timezone
const queryTimezone = ref('')
const timezones = ref([])
const filteredTimezones = computed(() => {
  if (queryTimezone.value != null || queryTimezone.value != '') {
    return timezones.value.filter((item) => item.includes(queryTimezone.value))
  }
  return timezones.value
})



async function save() {
  form.value.reportValidity()
  needCurrentPassword.value = false
  errors.value = {}
  if (
    user.value.password != undefined &&
    user.value.password != null &&
    user.value.password != '' &&
    !companyStore?.company?.force_oauth
  ) {
    if (user.value.password != user.value.password_confirmation) {
      errors.value['password'] = true
      await nextTick()
      password.value?.focus()
      errors.value = { password: [$t('errors.passwords_do_not_match')] }
      return
    } else {
      errors.value = {}
    }
    if (
      !authUserStore.user.needsInitialPassword &&
      (user.value.current_password == undefined || user.value.current_password == null)
    ) {
      needCurrentPassword.value = true
      await nextTick()
      current_password.value?.focus()
      return
    }
  }
  if (
    user.value.current_password != null &&
    user.value.current_password != '' &&
    user.value.password == null &&
    user.value.password_confirmation == null
  ) {
    errors.value = { current_password: [$t('errors.my_account.current_password')] }

    return
  } else {
    errors.value = {}
  }
  if (user.value.current_password == null || user.value.current_password == '') {
    delete user.value.current_password
  }
  if (user.value.password == null || user.value.password == '') {
    user.value.password = null
  }
  if (user.value.password_confirmation == null || user.value.password_confirmation == '') {
    user.value.password_confirmation = null
  }
  isLoading.value = true
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      authUserStore.user.id,
      user.value
    )
    alertsStore.successfullySaved()
    authUserStore.user.language = user.value.language
    console.log(i18n)
    i18n.global.locale.value = user.value.language ?? 'de'
    user.value.current_password = null
    user.value.password = null
    user.value.password_confirmation = null
    if (user.value.current_password != undefined) {
      delete user.value.current_password
    }
    useAuthUserStore().internalUpdateUserData(response.data)
  } catch (error) {
    if (error.response.status == 422) {
      if (user.value.current_password != undefined) {
        delete user.value.current_password
      }

      errors.value = apiService.convertValidationErrors(error)
    } else if (apiService.checkIfServerError(error)) {
      //
    } else {
      captureException(error)
      alertsStore.error($t('errors.ups'))
    }
  }
  isLoading.value = false
}

async function logout() {
  if (
    useTimeEntryStore().timeEntries.filter((item) => timelinkStoresService.isTempId(item.id))
      .length > 0
  ) {
    if (!confirm($t('logout_confirm'))) {
      return
    }
  }
  isLoading.value = true
  apiService.abortAll()
  apiService.resetSignal()
  desktopService.setLoggedOut()
  try {
    let result = await useAuthUserStore().logout(true)
    if (!result) {
      // alert('Ups. Sie scheinen sich ohne Verbindung zu Timelink abmelden zu wollen.')22
      try {
        let check = await useAuthUserStore().checkLoginStatus()
        if (check == null) {
          // useAuthUserStore().logout(false)
        }
      } catch (error) {
        if (error?.response?.status != 401) {
          captureException(error)
          logoutError.value = true
        }
        // alert('Fehler beim Abmelden!')
      }
    }
  } catch (error) {
    // captureException(error)
    try {
      useAuthUserStore().checkLoginStatus()
    } catch (err) {
      if (error?.response?.status != 401) {
        captureException(error)
        logoutError.value = true
      }
    }
  }
  isLoading.value = false
}
function resetQueryTimezone(isOpen = false) {
  if (isOpen) {
    return
  }
  timelinkStoresService.setOrRenewTimeout(
    'myaccount',
    'queryTimezone',
    () => {
      queryTimezone.value = ''
    },
    120
  )
}

/**
 * @type {import('vue').Ref<?{
 *  id: number,
 *  name: string,
 *  abilities: string[],
 *  last_used_at: string,
 *  expires_at: ?string,
 *  created_at: ?string,
 *  updated_at: ?string
 * }>}
 */
const apiToken = ref(null)
const plainApiToken = ref(null)
const showCreateTokenModal = ref(false)
const showApiTokenDeleteModal = ref(false)

async function fetchTokenData() {
  isLoading.value = true
  try {
    let response = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/token')
    console.log(response.data)
    if (response?.data) {
      apiToken.value = response.data
    }
    else {
      throw Error('No token data given!')
    }
  }
  catch (error) {
    console.log(error?.response?.status == 404)
    if (error?.response?.status == 404) {
      //
    }
    else {
      captureException(error)
    }
  }
  isLoading.value = false
}

function showApiTokenModalOrCreate() {
  if (apiToken.value) {
    showCreateTokenModal.value = true
  }
  else {
    createToken()
  }
}

async function createToken() {
  isLoading.value = true
  try {
    let response = await apiService.post(import.meta.env.VITE_API_URL + '/api/v1/token')
    if (response?.success) {

      apiToken.value = response.data
      plainApiToken.value = response.token

      alertsStore.success($t('myaccount.api_token.created.successfully'), 5)
      alertsStore.info($t('myaccount.api_token.copy_token'), 30)
    }
  }
  catch (error) {
    captureException(error)
  }
  showCreateTokenModal.value = false
  isLoading.value = false
}

async function deleteToken() {
  isLoading.value = true
  try {
    let response = await apiService.delete(import.meta.env.VITE_API_URL + '/api/v1/token')
    if (response.success) {
      alertsStore.success($t('myaccount.api_token.deleted.successfully'), 10)
      apiToken.value = null
      plainApiToken.value = null
    }
  }
  catch (error) {
    captureException(error)
  }
  showApiTokenDeleteModal.value = false
  isLoading.value = false
  fetchTokenData()
}

async function copyToClipboard() {
  try {
    await navigator.clipboard.writeText(plainApiToken.value)
    alertsStore.success($t('myaccount.api_token.copy_token_to_clipboard'), 5)
  }
  catch (error) {
    console.error(error.message)
  }
}



onMounted(() => {
  timezones.value = Intl.supportedValuesOf('timeZone')
  user.value = { ...authUserStore.user }
  fetchTokenData()
})

</script>
